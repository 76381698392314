:root {
  --text-color: wheat;
}

body {
  background-color: black;
}

.app-container {
  min-height: 100vh;
  height: fit-content;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
}

.card {
  background-color: rgb(33, 37, 41);
  color: var(--text-color);
  width: 240px;
}

.title-container {
  width: 100vw;
  margin: 10%;
  text-align: center;
  color: var(--text-color);
}

.fact-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  gap: 20px;
}